<template>
  <LxpMobileHeader v-if="isMobile" title="글쓰기" >
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="exitWrite">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="isSSM" class="util util-more">
        <div class="dropdown" :class="{'is-active': toggles.mobileMenu}" data-offset="header-right">
          <button class="dropdown-btn" @click="toggles.mobileMenu = !toggles.mobileMenu"><i class="icon-more"></i></button>
          <div class="dropdown-target" @click.stop="toggles.mobileMenu = false">
            <div class="dropdown-box">
              <ul class="dropdown-option-list">
                <li class="dropdown-option-item">
                  <a class="dropdown-option-link" @click="clickSaveTemp"><span class="dropdown-option-text">임시저장</span></a>
                </li>
                <li class="dropdown-option-item">
                  <a class="dropdown-option-link" @click="clickSave"><span class="dropdown-option-text">등록</span></a>
                </li>
                <li v-if="targetSn > 0" class="dropdown-option-item">
                  <a class="dropdown-option-link" @click="clickDelete"><span class="dropdown-option-text">삭제</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="util">
        <button class="util-actions-yellow" @click="clickSave">{{ pageMd === 'board' && targetSn > 0 ? '수정' : '등록' }}</button>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-sdl-mento">
    <!-- main-content -->
    <div v-if="!isMobile" class="main-content">
      <!-- editor-container -->
      <div class="editor-container editor-component board-editor-container">
        <!-- editor-aside -->
        <div class="editor-aside">
          <div class="editor-aside-inner overflow-y-auto pt-6">
            <div v-if="pageMd === 'board'" class="aside-kb-form-title">
              <input v-model.trim="params.title" type="text" class="kb-form-title-input" placeholder="제목 입력">
            </div>
            <div class="aside-files aside-kb-form-filelist">
              <ul class="file-list">
                <li class="file-item" v-for="(item, idx) in files.files" :key="idx">
                  <div class="file">
                    <p class="filename" :title="item.name">
                      <span class="filename-base">{{item.name}}</span>
                      <button type="button" class="ms-1" @click="removeFile(idx)"><i class="icon-x"></i></button>
                    </p>
                    <p class="filesize">{{convertToStorageBytes(item.size)}}</p>
                  </div>
                </li>
              </ul>
              <div class="file-upload">
                <div class="kb-form-file">
                  <label class="kb-form-file-btn kb-btn kb-btn-secondary">
                    <span class="text">파일첨부</span>
                    <InputUpload v-model="files" :max-qty="5" :extensions="atchFileExts" sequence-key="lrnPostFileAtchSn" />
                  </label>
                </div>
                <p class="kb-form-file-tip">파일첨부는 최대 5개까지 가능합니다.</p>
              </div>
            </div>
            <div class="aside-bottom-buttons aside-actions">
              <button v-if="isSSM" class="kb-btn kb-btn-light-gray" @click="clickSaveTemp">
                <span class="text">임시저장</span>
              </button>
              <button class="kb-btn kb-btn-dark " @click="clickSave">
                <span class="text">{{ pageMd === 'board' && targetSn > 0 ? '수정' : '등록' }}</span>
              </button>
              <div class="d-flex w-100">
                <button class="kb-btn kb-btn-light-gray" @click="exitWrite">
                  <span class="text">나가기</span>
                </button>
                <button v-if="pageMd === 'board' && targetSn > 0" class="kb-btn kb-btn-primary" @click="clickDelete">
                  <span class="text">삭제</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- //editor-aside -->
        <!-- editor-body -->
        <div class="editor-content">
          <div class="editor-content-body">
            <div v-show="false" ref="pageEditor"></div>
            <iframe v-if="isInApp" ref="editorIframe" width="100%" height="100%"></iframe>
          </div>
          <div class="editor-content-footer">
            <div class="content-source"></div>
<!--            <div class="content-byte">-->
<!--              <span class="current">{{ currentCnCount }}</span>-->
<!--              <span class="total">3000</span>-->
<!--            </div>-->
          </div>
        </div>
        <!-- //editor-body -->
      </div>
      <!-- //editor-container -->
    </div>
    <!-- //main-content -->
    <div v-else class="main-content main-component">
      <div class="board-editor-container">
        <div class="editor-body">
          <div v-if="pageMd === 'board'" class="editor-body-top">
            <input v-model.trim="params.title" type="text" class="kb-form-title-input" placeholder="제목 입력">
          </div>
          <div class="editor-content ms-0">
            <div class="editor-content-body">
              <div v-show="false" ref="pageEditor"></div>
              <iframe v-if="isInApp" ref="editorIframe" width="100%" height="100%"></iframe>
            </div>
            <div class="editor-content-footer">
              <div class="content-source"></div>
            </div>
          </div>
          <div v-if="pageMd === 'board'" class="">
            <div class="file-upload">
              <div class="kb-form-file">
                <label class="kb-form-file-btn kb-btn kb-btn-secondary">
                  <span class="text">파일첨부</span>
                  <InputUpload v-model="files" :max-qty="5" :extensions="atchFileExts" sequence-key="lrnPostFileAtchSn" />
                </label>
              </div>
              <p class="kb-form-file-tip">파일첨부는 최대 5개까지 가능합니다.</p>
            </div>
            <ul v-if="files.files.length > 0" class="p-2 mt-2 border">
              <li class="w-100" v-for="(item, idx) in files.files" :key="idx">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="filename" :title="item.name">
                    <span class="text me-2">{{item.name}}</span>
                    <span class="text text-muted">{{convertToStorageBytes(item.size)}}</span>
                  </div>
                  <button type="button" class="ms-1" @click="removeFile(idx)"><i class="icon-x"></i></button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div v-if="toggles.saving" class="fixed-top bg-dark opacity-75 d-flex flex-column justify-content-center align-items-center vw-100 vh-100">
    <LoadingDiv />
    <h3 class="text-primary fs-3">처리 중입니다.</h3>
  </div>
</template>
<script>

import {computed, onMounted, onUnmounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {ACT_GET_CRSE_DIST_MENTORING} from '@/store/modules/course/course';
import {
  getItem,
  isSuccess,
  lengthCheck,
  setParams,
  convertToStorageBytes,
  getItems,
  isUploadSuccess
} from '@/assets/js/util';
import {
  ACT_DELETE_LRN_BOARD,
  ACT_GET_LRN_BOARD,
  ACT_INSERT_LRN_BOARD,
  ACT_UPDATE_LRN_BOARD,
  ACT_UPDATE_LRN_REPORT,
  ACT_UPLOAD_LEARN_BOARD_ATCH,
} from '@/store/modules/board/board';
import {useAlert} from '@/assets/js/modules/common/alert';
import navigationUtils from '@/assets/js/navigationUtils';
import {initEditor} from '@/assets/js/ui.init';
import {getReportTable, mentoringBoardTable, ssmEventBoardTable} from '@/assets/js/modules/course/mentoring-common';
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import InputUpload from "@/components/common/InputUpload.vue";
import {removeUpload} from "@/assets/js/modules/hrd/hrd-common";
import {atchFileExts} from "@/assets/js/file-util";
import LoadingDiv from "@/components/common/LoadingDiv";

export default {
  name: 'MentoringWrite',
  components: {LxpMobileHeader, InputUpload, LoadingDiv},
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showLoading, hideLoading, showConfirm, showMessage} = useAlert();

    const session = computed(() => store.state.auth.session);
    const distCrseSn = computed(() => route.params.distCrseSn);
    const view = computed(() => route.params.view);
    const pageMd = computed(() => (view.value == 'board' || view.value == 'diary') ? 'board':view.value);
    const currentQuery = computed(() => route.query);
    const isSSM = computed(() => route.name.indexOf('SSM') === 0);
    const isMentoring = computed(() => route.name.indexOf('Mentoring') === 0);

    const targetSn = computed(() => route.params.targetSn ? parseInt(route.params.targetSn) : 0);
    const isInApp = ref(navigator && navigator.appVersion && navigator.appVersion.indexOf('eHRD_app') > -1 && navigationUtils.iOS());
    const pageEditor = ref(null);
    const editorIframe = ref(null);
    const toggles = reactive({
        mobileMenu: false,
        saving: false,
    });

    const params = reactive({
      title: '',
      cn: '',
      distCrseGrpSeqSn: route.params.distCrseGrpSeqSn ? route.params.distCrseGrpSeqSn : null,
      stt: '00',
    });
    const files = ref({files: [], removed: [], binaries: []});

    const removeFile = (idx) => {
      files.value = removeUpload(files.value, 'lrnPostFileAtchSn', idx);
    }

    let editorInstance = null;
    const tryCount = ref(0);

    const setInitTableTemplate = (editor) => {
      editorInstance = editor;
      editor.setData(params.cn);
      hideLoading();
    }

    const filesParam = ref([]);
    const fileUploadAction = async () => {
      filesParam.value = [];
      const newFileList = files.value.files.filter(file => !file.lrnPostFileAtchSn);
      if(newFileList.length > 0){
        await store.dispatch(`board/${ACT_UPLOAD_LEARN_BOARD_ATCH}`, {contentsType: view.value, files: newFileList}).then(res => {
          if(!isUploadSuccess(res)){
            showMessage('파일 업로드 중 오류가 발생했습니다.')
          }else if(lengthCheck(res.data)){
            filesParam.value = getItems(res.data);
          }
        })
      }
    }


    const getSaveAction = async () => {
      if (pageMd.value === 'board') {
        await fileUploadAction();
        return (targetSn.value > 0 ?
            store.dispatch(`board/${ACT_UPDATE_LRN_BOARD}`, {lrnPostSn: targetSn.value, params: {files: filesParam.value, removedFiles: files.value.removed, ...params}}) :
            store.dispatch(`board/${ACT_INSERT_LRN_BOARD}`, {distCrseSn: distCrseSn.value, files: filesParam.value, ...params}));
      } else if (pageMd.value === 'report') {
        return store.dispatch(`board/${ACT_UPDATE_LRN_REPORT}`, {lrnTrgtGrpDtlSn: targetSn.value, params});
      }
    }

    const targetName = computed(() => {
      if (route.name.indexOf('Mentoring') === 0) {
        return 'Mentoring';
      } else if(isSSM.value) {
        return 'SSM';
      }
      return 'Mentoring';
    });

    const targetSplCrseTyCdDcd = computed(() => {
      if (route.name.indexOf('Mentoring') === 0) {
        return '2066005';
      } else if(isSSM.value) {
        return '2066016';
      }
      return '2066000';
    });

    const getTargetText = () => {
      if(targetName.value === 'Mentoring') {
        if (view.value === 'board') {
          return '멘토링 일지';
        } else if (view.value === 'report') {
          return '종합활동보고서';
        }
      } else if (targetName.value === 'SSM') {
        if (view.value === 'board') {
          return '학습노트';
        } else if (view.value === 'diary') {
          return '우리의 이야기';
        }
      }
      return '-';
    }

    const deleteWrite = () => {
      // board 일때만 삭제
      if (pageMd.value === 'board') {
        store.dispatch(`board/${ACT_DELETE_LRN_BOARD}`, targetSn.value).then(res => {
          if(isSuccess(res)){
            showMessage(`${getTargetText()}가 삭제되었습니다.`);
            router.push({name: `${targetName.value}Sub`, params: {view: view.value}, query: currentQuery.value});
          }else{
            showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
          }
        }).catch(e => {
          console.error(e);
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        });
      }
    }

    const saveWrite = () => {
      toggles.saving = true;
      getSaveAction().then(res => {
        hideLoading();
        if(isSuccess(res)){
          showMessage({
              text:`${getTargetText()}가 ${pageMd.value === 'board' && targetSn.value > 0 ? '수정' : '등록'}되었습니다.`,
              callback: () => {
                if(params.stt == '01'){
                  if(res.lrnPostSn){
                    router.push({params: {...route.params, targetSn: res.lrnPostSn}, query: currentQuery.value});
                  }
                }else{
                  router.push({name: `${targetName.value}Sub`, params: {view: view.value}, query: currentQuery.value});
                }
              }
          });
        }else{
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        hideLoading();
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      }).finally(() => {toggles.saving = false;});
    }

    const validateContents = () => {
      params.cn = editorInstance.getData();

      let validated = false;
      let msg = '';

      if(pageMd.value === 'board') {
        if(!params.title) {
          msg = '제목을 입력해주세요.';
        } else if(!params.cn) {
          msg = '내용을 입력해주세요.';
        } else {
          validated = true;
        }
      } else if (pageMd.value === 'report'){
        if(!params.cn) {
          msg = '내용을 입력해주세요.';
        } else {
          validated = true;
        }
      } else {
        msg = '잘못된 접근입니다.';
      }

      if(!validated ) {
        showMessage(msg);
      }

      return validated;

    }

    const clickDelete = () => {
      const targetText = getTargetText();
      showConfirm({
        title: targetText,
        text : `${targetText}를 삭제 하시겠습니까?`,
        callback: deleteWrite
      });
    }


    const clickSave = () => {
      if(validateContents()) {
        const targetText = getTargetText();
        showConfirm({
          title: targetText,
          text : `${targetText}를 ${pageMd.value === 'board' && targetSn.value > 0 ? '수정' : '등록'} 하시겠습니까?`,
          callback: () => {
            params.stt = '00';
            saveWrite();
          }
        });
      }
    }

    const clickSaveTemp = () => {
      if(validateContents()) {
        const targetText = getTargetText();
        showConfirm({
          title: targetText,
          text : `${targetText}를 임시저장 하시겠습니까?`,
          callback: () => {
            params.stt = '01';
            saveWrite();
          }
        });
      }
    }

    const exitWrite = () => {
      showConfirm({
        text : '페이지를 나가면<br>작성중이던 내용은 삭제됩니다.',
        callback: () => {
          router.push({name: `${targetName.value}Sub`, params: {view: view.value}, query: currentQuery.value});
        }
      });
    }

    const goBackWithErrorAlert = () => {
      hideLoading();
      showMessage('잘못된 접근입니다.');
      // 권한이 없는 경우 main 으로 이동
      router.push({name: 'Main'});
    }

    onMounted(() => {
      if (distCrseSn.value > 0) {
        showLoading();
        // 해당 멘토링인지 검증...
        store.dispatch(`course/${ACT_GET_CRSE_DIST_MENTORING}`, {splCrseTyCdDcd: targetSplCrseTyCdDcd.value, distCrseSn: distCrseSn.value}).then(res => {
          if (lengthCheck(res)){
            const course = getItem(res);
            if(course.mentorings && course.mentorings.length > 0) {
              if(pageMd.value === 'board') {
                if (targetSn.value > 0) {
                  store.dispatch(`board/${ACT_GET_LRN_BOARD}`, targetSn.value).then(boardRes => {
                    if(lengthCheck(boardRes)){
                      const item = getItem(boardRes);
                      if(session.value.lrnerId === item.lrnerId){
                        setParams(params, item);
                        files.value.files = item.files.map(file => {
                          return {
                            name: file.fileNm,
                            size: file.fileSz,
                            lrnPostFileAtchSn: file.lrnPostFileAtchSn,
                          }
                        });
                        initEditor(
                            pageEditor.value,
                            editorIframe.value,
                            tryCount.value,
                            setInitTableTemplate,
                            isInApp.value,
                            `/v1/app/learns/boards/${targetName.value.toLowerCase()}/upload`
                        );
                      } else{
                        // 권한이 없는 멘토링 일지 접근
                        goBackWithErrorAlert();
                      }
                    } else {
                      // 권한이 없는 멘토링 일지 접근
                      goBackWithErrorAlert();
                    }
                  });
                } else {
                  // 멘토링 일지는 새롭게 작성 가능, 기본 템플릿 적용
                  if(isMentoring.value) params.cn = mentoringBoardTable;
                  else if(isSSM.value && getTargetText() == '학습노트') params.cn = ssmEventBoardTable;
                  initEditor(pageEditor.value, editorIframe.value, tryCount.value, setInitTableTemplate, isInApp.value, `/v1/app/learns/boards/${targetName.value.toLowerCase()}/upload`);
                }
              } else if (pageMd.value === 'report') {
                const myReport = course.mentorings[0].learners.find(x => x.lrnTrgtGrpDtlSn === targetSn.value);
                if(myReport && myReport.lrnerId === session.value.lrnerId) {
                  params.cn = myReport.selfIntro || getReportTable(myReport.grpRoleCdDcd);
                  initEditor(pageEditor.value, editorIframe.value, tryCount.value, setInitTableTemplate, isInApp.value, `/v1/app/learns/boards/${targetName.value.toLowerCase()}/upload`);
                } else {
                  goBackWithErrorAlert();
                }
              }
            } else {
              // 멘토링 정보가 없는 차수번호
              goBackWithErrorAlert();
            }
          } else {
            // 멘토링 정보가 없는 차수번호
            goBackWithErrorAlert();
          }

        }).catch(e => {
          console.error(e);
          goBackWithErrorAlert();
        });
      }
    });

    onUnmounted(() => {
      if(editorInstance !== null) {
        editorInstance.destroy();
      }
    });

    return {
      pageEditor,
      editorIframe,
      pageMd,
      isSSM,
      targetSn,
      toggles,
      isInApp,
      params,
      files,
      currentQuery,
      removeFile,
      clickDelete,
      clickSave,
      clickSaveTemp,
      exitWrite,
      convertToStorageBytes,
      atchFileExts,
      isMobile: navigationUtils.any(),
    }
  }
};
</script>
<style>
/* 멘토링 일지작성 템플릿 UI 커스텀 사용 */
.ck-content .kb-table table th {
  background: #bfbfbf !important;
}
</style>
